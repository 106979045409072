import React, { useEffect, useRef, useState } from 'react'
import Logo from '../../assets/imgs/logo.png'
import './Header.scss'

const Header = () => {
    const headerRef = useRef()
    const [burgerI, setBurger] = useState("close")
    const [openBurger, setOpenBurger] = useState(false)

    const handleBurger = () => {
        setBurger(burgerI === "open" ? "close" : "open")
        setOpenBurger(!openBurger);
    }

    const handleState = () => {
        window.scrollTo(0, 0)
        window.history.pushState({}, null, "https://bigimot.net/")
    }

    // openBurger
    //     ? (document.body.style.overflow = "hidden")
    //     : (document.body.style.overflow = "auto")

    useEffect(() => {
        let prevScrollpos = window.scrollY
        window.onscroll = function () {
            const currentScrollPos = window.scrollY
            const header = headerRef.current
            if (prevScrollpos > currentScrollPos) {
                header.style.top = "0"
            } else {
                header.style.top = "-111px"
            }
            prevScrollpos = currentScrollPos
        }
    }, [])

    return (
        <header className='header' ref={headerRef}>
            <div className="container">
                <nav className='header__nav'>
                    <img
                        src={Logo}
                        alt="Logo"
                        onClick={handleState}
                    />

                    <ul className={`header__list ${openBurger ? "header__list-active" : ""}`}>
                        <li><a onClick={handleBurger} href="#about">About</a></li>
                        <li><a onClick={handleBurger} href="#solutions">Solutions</a></li>
                        <li><a onClick={handleBurger} href="#projects">Projects</a></li>
                        <li><a onClick={handleBurger} href="#contacts">Contacts</a></li>
                    </ul>

                    <div className="header__burger" role="button" onClick={handleBurger}>
                        <i className={burgerI}></i>
                        <i className={burgerI}></i>
                        <i className={burgerI}></i>
                    </div>
                </nav>
            </div>
        </header >
    )
}

export default Header