import React from 'react'
import About from '../components/about/About'
import Header from '../components/header/Header'
import Main from '../components/main/Main'
import Solutions from '../components/solutions/Solutions'
import Projects from '../components/projects/Projects'
import Contacts from '../components/contacts/Contacts'
import Footer from '../components/footer/Footer'

const App = () => {
    return (
        <>
            <Header />
            <Main />
            <About />
            <Solutions />
            <Projects />
            <Contacts />
            <Footer />
        </>
    )
}

export default App