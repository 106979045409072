import React from 'react'
import { solutionCols } from './data'
import './Solutions.scss'

const Solutions = () => {
    return (
        <section id='solutions'>
            <div className="container">
                <div className="solutions">
                    <div className='solutions__about'>
                        <h4 className='title'>What we do</h4>
                        <h3 className='subtitle'>Our mission is to make your business better through technology.</h3>
                        <p className='text'>We develop business solutions which allow any business to achieve its goals: to find new customers and retain current ones, increase sales and improve brand image metrics.</p>
                    </div>

                    <div className="solutions__row">
                        {solutionCols.map((el) => {
                            return (
                                <div className="solutions__col" key={el.id}>
                                    <img src={el.img} alt="Solutions Img" />
                                    <h4>{el.title}</h4>
                                    <p className='text'>{el.text}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section >
    )
}

export default Solutions