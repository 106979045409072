import React from 'react'
// import { projectsAll } from '../../assets/svgs/svgs'
import { projectCols } from './data'
import './Projects.scss'

const Projects = () => {
    return (
        <article id='projects'>
            <div className="container">
                <div className="projects">
                    <div className="projects__about">
                        <div className='projects__about-texts'>
                            <h4 className='title'>Our Works</h4>
                            <h3 className='subtitle'>Here are few our recent works.</h3>
                        </div>
                        {/* <button>View All {projectsAll.icon}</button> */}
                    </div>

                    <div className="projects__row">
                        {projectCols.map((el) => {
                            return (
                                <div key={el.id} className='projects__col'>
                                    <img src={el.img} alt="Projects Img" />
                                    <h2>{el.name}</h2>
                                    <p className='text'>{el.text}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </article>
    )
}

export default Projects