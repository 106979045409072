import Mobile from "../../assets/imgs/mobile.png";
import Web from "../../assets/imgs/web.png";
import Qa from "../../assets/imgs/qa.png";
import Ui from "../../assets/imgs/ui.png";
import Crm from "../../assets/imgs/crm.png";
import Project from "../../assets/imgs/project.png";

export const solutionCols = [
  {
    id: "1",
    img: Mobile,
    title: "Mobile app development",
    text: "Build native mobile apps for iOS & Android platform.",
  },
  {
    id: "2",
    img: Web,
    title: "Web development",
    text: "E-commerce, landing pages, motivation programs, loyalty programs.",
  },
  {
    id: "3",
    img: Qa,
    title: "QA & QC managment",
    text: "Expertise to ensue high-quality of products.",
  },
  {
    id: "4",
    img: Ui,
    title: "UI & UX design",
    text: "Users interface architecture and usability.",
  },
  {
    id: "5",
    img: Crm,
    title: "CRM strategy",
    text: "Automation and machine learning service for automatic customer interactions and customer retention tool.",
  },
  {
    id: "6",
    img: Project,
    title: "Project support",
    text: "Management & technical support.",
  },
];
