import Project1 from "../../assets/imgs/project_1.png";
import Project2 from "../../assets/imgs/project_2.png";
import Project3 from "../../assets/imgs/project_3.png";
import Project4 from "../../assets/imgs/project_4.png";

export const projectCols = [
  {
    id: "1",
    img: Project1,
    name: "Project #1",
    text: "Education platform for development soft and hard skills in directions: IT, web-design, business-education, beauty, health&sport, finances, etc.",
  },
  {
    id: "2",
    img: Project2,
    name: "Project #2",
    text: "Secure messenger with end-to-end encryption. Consist of high-quality voice & video calls, groups, channels and conference calls Provided for integrations with B2B platforms.",
  },
  {
    id: "3",
    img: Project3,
    name: "Project #3",
    text: "Mobile application and web-site service. Consists of application for customers and for curriers & business integration for restaurants.",
  },
  {
    id: "4",
    img: Project4,
    name: "Project #4",
    text: "Mobile e-commerce application for creating daily outfits.",
  },
];
