import React from 'react'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Scrollbar, A11y, Autoplay } from "swiper"
import "swiper/scss"
import "swiper/scss/navigation"
import { contactCards } from './data'
import { testimonialSymbol } from '../../assets/svgs/svgs'
import './Contacts.scss'

const Contacts = () => {
    return (
        <article id='contacts'>
            <div className="container">
                <div className="contacts">
                    <div className="contacts__top">
                        <div className="contacts__about">
                            <h4 className='title'>Testimonial</h4>
                            <h3>What Customers Say About Us</h3>
                        </div>

                        <div className='contacts__arrows'>
                            <button className='review-swiper-button-prev'>
                                <BsChevronLeft className='contacts__arrows-icon' />
                            </button>
                            <button className='review-swiper-button-next'>
                                <BsChevronRight className='contacts__arrows-icon' />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container2">
                <Swiper
                    className="contacts__swiper"
                    modules={[Navigation, Scrollbar, A11y, Autoplay]}
                    // spaceBetween={32}
                    slidesPerView="auto"
                    navigation={{
                        nextEl: '.review-swiper-button-next',
                        prevEl: '.review-swiper-button-prev',
                    }}
                    loop={true}
                // autoplay={{ delay: 3000, disableOnInteraction: true }}
                >
                    {contactCards.map((el) => {
                        return (
                            <SwiperSlide key={el.id} className='contacts__slide'>
                                <div>
                                    {testimonialSymbol.icon}
                                    <p className='contacts__slide-p'>{el.text}</p>
                                </div>

                                {/* <div className='contacts__slide-main'> */}
                                <div className="contacts__slide-user">
                                    <img src={el.img} alt="symbol" />
                                    <div className='contacts__slide-aboutUser'>
                                        <h6>{el.name}</h6>
                                        <p>{el.role}</p>
                                    </div>
                                </div>

                                {/* <div className='contacts__slide-stars'>
                                        {testimonialStar.icon}
                                        {testimonialStar.icon}
                                        {testimonialStar.icon}
                                        {testimonialStar.icon}
                                        {testimonialStar.icon}
                                        <span>{el.stars}</span>
                                    </div> */}
                                {/* </div> */}
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
        </article>
    )
}

export default Contacts