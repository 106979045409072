import React from 'react'
import AboutImg from '../../assets/imgs/about_img.png'
import './About.scss'


const About = () => {
    return (
        <section id='about'>
            <div className="container">
                <div className="about">
                    <img src={AboutImg} className='about__img' alt="About Img" />

                    <div>
                        <h4 className='title'>About the studio</h4>
                        <h3 className='subtitle'>Our mission is to make your business better through technology.</h3>
                        <p className='text'>We create B2B solutions which covers vast majority of business goals. We work best as an extension of your team - pitching ideas, thinking outside the box, developing products for your business and focusing on solving your objectives. Our customers range from small and medium – sized business to global companies in different spheres. Our team comprised of professionals that combine a huge amount of experience: IT, website creation, mobile application development, QA&QС. digital solutions, UI&UX and production. What sets us apart is our dedicated support, advanced management level and customized strategic approach.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About