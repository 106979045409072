import User1 from "../../assets/imgs/testimonial_one.png";
import User2 from "../../assets/imgs/testimonial_two.png";
import User3 from "../../assets/imgs/testimonial_three.png";

export const contactCards = [
  {
    id: "1",
    text: "I express my gratitude to Bigimot for its high professionalism and customer orientation.",
    img: User1,
    name: "Annie Williams",
    role: "Ceo, card center",
    // stars: "5.0",
  },
  {
    id: "2",
    text: "A team of real professionals who were able to solve the problem of my department",
    img: User2,
    name: "James Johnson",
    role: "Hr Manager",
    // stars: "5.0",
  },
  {
    id: "3",
    text: "It was very pleasant to work in a team with such professionals. The project was done on time, taking into account all the wishes",
    img: User3,
    name: "Kate Davis",
    role: "Product Manager",
    // stars: "5.0",
  },
];
