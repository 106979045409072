import React from 'react'
import MainImg from '../../assets/imgs/main_img.png'
import './Main.scss'

const Main = () => {
    return (
        <main>
            <div className="container2">
                <div className="main">
                    <div className="main__about">
                        <h1>We don’t just make things that are smaller, we make things that are <span>better</span><span>.</span></h1>
                        <button>Let’s get start</button>
                    </div>

                    <div className='main__img'>
                        <img src={MainImg} alt="Main Img" />
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Main