import React from 'react'
import Logo from '../../assets/imgs/logo.png'
import Facebook from '../../assets/imgs/fb.png'
import Instagram from '../../assets/imgs/instagram.png'
import Vk from '../../assets/imgs/vk.png'
import Linkedin from '../../assets/imgs/linkedin.png'
import './Footer.scss'

const Footer = () => {
    const yearNow = new Date().getFullYear();

    const handleClick = () => {
        window.scrollTo(0, 0)
        window.history.pushState({}, null, "https://bigimot.net/");
    }

    return (
        <footer>
            <div className="container">
                <div className="footer">
                    <div className="footer__context">
                        <div className='footer__context-top'>
                            <img
                                onClick={handleClick}
                                src={Logo}
                                alt="Footer Logo"
                            />
                            <h6>Digital Development Studio</h6>
                        </div>
                        <span>All rights reserved 2022-{yearNow}.</span>
                    </div>

                    <div className='footer__links'>
                        <nav className="footer__nav">
                            <h4>About us</h4>
                            <ul className='footer__nav-list'>
                                <li><a href='#about'>About</a></li>
                                <li><a href='#solutions'>Solutions</a></li>
                                <li><a href='#projects'>Projects</a></li>
                                <li><a href='#contacts'>Contacts</a></li>
                            </ul>
                        </nav>

                        <nav className="footer__nav">
                            <h4>Contact us</h4>
                            <ul className='footer__nav-list'>
                                <li><a href="mailto: info@bigimot.net">info@bigimot.net</a></li>
                                <li><a target="/blank" href='https://www.google.com/maps/place/13+Pushkin+St,+Yerevan,+%D0%90%D1%80%D0%BC%D0%B5%D0%BD%D0%B8%D1%8F/@40.1817684,44.5117967,17z/data=!3m1!4b1!4m5!3m4!1s0x406abcfca91aac55:0xeb5eea03716b1b6e!8m2!3d40.1817643!4d44.5139854'>Armenia, Yerevan, Pushkin Str. 13</a></li>
                                <ul className='footer__nav-smm'>
                                    <li><a target="_blank" href="https://vk.com/club218510457" rel="noreferrer"><img src={Vk} alt="Vk" /></a></li>
                                    <li><a target="_blank" href="https://www.facebook.com/people/Bigimot-Studio/100089745264880/" rel="noreferrer"><img src={Facebook} alt="Facebook" /></a></li>
                                    <li><a target="_blank" href="https://www.instagram.com/bigimot_s/?igshid=YmMyMTA2M2Y%3D" rel="noreferrer"><img src={Instagram} alt="Instagram" /></a></li>
                                    <li><a target="_blank" href="https://www.linkedin.com/in/bigimot-studio-28154b263/?originalSubdomain=am" rel="noreferrer"><img src={Linkedin} alt="Linkedin" /></a></li>
                                </ul>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer