// export const projectsAll = {
//   icon: (
//     <svg
//       style={{ marginLeft: "13px" }}
//       width="24"
//       height="12"
//       viewBox="0 0 24 12"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M23.725 5.33645C23.7248 5.33617 23.7245 5.33584 23.7242 5.33556L18.8256 0.460558C18.4586 0.0953549 17.865 0.0967139 17.4997 0.463745C17.1345 0.830729 17.1359 1.42431 17.5028 1.78956L20.7918 5.06256H0.9375C0.419719 5.06256 0 5.48228 0 6.00006C0 6.51784 0.419719 6.93756 0.9375 6.93756H20.7917L17.5029 10.2106C17.1359 10.5758 17.1345 11.1694 17.4998 11.5364C17.865 11.9034 18.4587 11.9047 18.8256 11.5396L23.7242 6.66456C23.7245 6.66428 23.7248 6.66395 23.7251 6.66367C24.0923 6.2972 24.0911 5.7017 23.725 5.33645Z"
//         fill="#F66741"
//       />
//     </svg>
//   ),
// };

export const testimonialSymbol = {
  icon: (
    <svg
      width="36"
      height="33"
      viewBox="0 0 36 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.14362 11.7409C6.93351 10.5683 8.01596 9.61554 9.39096 8.88265C10.7952 8.14976 12.3896 7.78331 14.1742 7.78331C16.2513 7.78331 18.1237 8.29634 19.7912 9.32239C21.488 10.3484 22.8191 11.8142 23.7846 13.7197C24.7793 15.5959 25.2766 17.78 25.2766 20.2718C25.2766 22.7636 24.7793 24.977 23.7846 26.9118C22.8191 28.8173 21.488 30.2978 19.7912 31.3531C18.1237 32.4085 16.2513 32.9362 14.1742 32.9362C12.3604 32.9362 10.766 32.5844 9.39096 31.8808C8.04521 31.1479 6.96277 30.2098 6.14362 29.0665V32.5404H0V0H6.14362V11.7409ZM19.0013 20.2718C19.0013 18.806 18.6941 17.5454 18.0798 16.4901C17.4947 15.4054 16.7048 14.5846 15.7101 14.0276C14.7447 13.4706 13.6915 13.1921 12.5505 13.1921C11.4388 13.1921 10.3856 13.4852 9.39096 14.0715C8.42553 14.6285 7.63564 15.4494 7.02128 16.534C6.43617 17.6187 6.14362 18.894 6.14362 20.3597C6.14362 21.8255 6.43617 23.1008 7.02128 24.1854C7.63564 25.2701 8.42553 26.1056 9.39096 26.6919C10.3856 27.2489 11.4388 27.5274 12.5505 27.5274C13.6915 27.5274 14.7447 27.2343 15.7101 26.648C16.7048 26.0616 17.4947 25.2261 18.0798 24.1415C18.6941 23.0568 19.0013 21.7669 19.0013 20.2718Z"
        fill="#F66741"
      />
      <path
        d="M32.2147 32.9363C31.0866 32.9363 30.1514 32.6021 29.4092 31.9337C28.6967 31.2362 28.3404 30.3789 28.3404 29.3618C28.3404 28.3447 28.6967 27.5019 29.4092 26.8335C30.1514 26.1361 31.0866 25.7874 32.2147 25.7874C33.3132 25.7874 34.2187 26.1361 34.9312 26.8335C35.6437 27.5019 36 28.3447 36 29.3618C36 30.3789 35.6437 31.2362 34.9312 31.9337C34.2187 32.6021 33.3132 32.9363 32.2147 32.9363Z"
        fill="#7A86F2"
      />
    </svg>
  ),
};

// export const testimonialStar = {
//   icon: (
//     <svg
//       width="16"
//       height="15"
//       viewBox="0 0 16 15"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M7.04894 0.92705C7.3483 0.00573921 8.6517 0.00573969 8.95106 0.92705L10.0206 4.21885C10.1545 4.63087 10.5385 4.90983 10.9717 4.90983H14.4329C15.4016 4.90983 15.8044 6.14945 15.0207 6.71885L12.2205 8.75329C11.87 9.00793 11.7234 9.4593 11.8572 9.87132L12.9268 13.1631C13.2261 14.0844 12.1717 14.8506 11.388 14.2812L8.58778 12.2467C8.2373 11.9921 7.7627 11.9921 7.41221 12.2467L4.61204 14.2812C3.82833 14.8506 2.77385 14.0844 3.0732 13.1631L4.14277 9.87132C4.27665 9.4593 4.12999 9.00793 3.7795 8.75329L0.979333 6.71885C0.195619 6.14945 0.598395 4.90983 1.56712 4.90983H5.02832C5.46154 4.90983 5.8455 4.63087 5.97937 4.21885L7.04894 0.92705Z"
//         fill="#FFDA16"
//       />
//     </svg>
//   ),
// };

// export const facebook = {
//   icon: (
//     <svg
//       width="12"
//       height="12"
//       viewBox="0 0 12 12"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <g clip-path="url(#clip0_1_143)">
//         <path
//           d="M7.9985 1.9925H9.094V0.0845C8.905 0.0585 8.255 0 7.49801 0C5.91851 0 4.83651 0.9935 4.83651 2.8195V4.5H3.09351V6.633H4.83651V12H6.97351V6.6335H8.646L8.9115 4.5005H6.97301V3.031C6.97351 2.4145 7.1395 1.9925 7.9985 1.9925V1.9925Z"
//           fill="#101237"
//         />
//       </g>
//       <defs>
//         <clipPath id="clip0_1_143">
//           <rect width="12" height="12" fill="white" />
//         </clipPath>
//       </defs>
//     </svg>
//   ),
// };

// export const instagram = {
//   icon: (
//     <svg
//       width="13"
//       height="13"
//       viewBox="0 0 13 13"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <g clip-path="url(#clip0_1_148)">
//         <path
//           d="M9.481 0H3.51898C1.57858 0 0 1.57858 0 3.51898V9.4811C0 11.4214 1.57858 13 3.51898 13H9.4811C11.4214 13 13 11.4214 13 9.4811V3.51898C13 1.57858 11.4214 0 9.481 0V0ZM12.2379 9.4811C12.2379 11.0012 11.0012 12.2379 9.481 12.2379H3.51898C1.99881 12.2379 0.762114 11.0012 0.762114 9.4811V3.51898C0.762114 1.99881 1.99881 0.762114 3.51898 0.762114H9.4811C11.0012 0.762114 12.2379 1.99881 12.2379 3.51898V9.4811Z"
//           fill="#101237"
//         />
//         <path
//           d="M6.49999 2.94531C4.53995 2.94531 2.9454 4.53986 2.9454 6.49989C2.9454 8.45993 4.53995 10.0545 6.49999 10.0545C8.46002 10.0545 10.0546 8.45993 10.0546 6.49989C10.0546 4.53986 8.46002 2.94531 6.49999 2.94531V2.94531ZM6.49999 9.29236C4.96029 9.29236 3.70752 8.03969 3.70752 6.49989C3.70752 4.96019 4.96029 3.70743 6.49999 3.70743C8.03978 3.70743 9.29245 4.96019 9.29245 6.49989C9.29245 8.03969 8.03978 9.29236 6.49999 9.29236V9.29236Z"
//           fill="#101237"
//         />
//         <path
//           d="M10.1396 1.68311C9.56035 1.68311 9.08923 2.15432 9.08923 2.73344C9.08923 3.31266 9.56035 3.78388 10.1396 3.78388C10.7188 3.78388 11.19 3.31266 11.19 2.73344C11.19 2.15422 10.7188 1.68311 10.1396 1.68311ZM10.1396 3.02166C9.98068 3.02166 9.85135 2.89233 9.85135 2.73344C9.85135 2.57445 9.98068 2.44522 10.1396 2.44522C10.2986 2.44522 10.4279 2.57445 10.4279 2.73344C10.4279 2.89233 10.2986 3.02166 10.1396 3.02166Z"
//           fill="#101237"
//         />
//       </g>
//       <defs>
//         <clipPath id="clip0_1_148">
//           <rect width="13" height="13" fill="white" />
//         </clipPath>
//       </defs>
//     </svg>
//   ),
// };

// export const twitter = {
//   icon: (
//     <svg
//       width="13"
//       height="13"
//       viewBox="0 0 13 13"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <g clip-path="url(#clip0_1_155)">
//         <path
//           d="M13 2.46919C12.5166 2.68125 12.0014 2.82181 11.4644 2.89006C12.0169 2.56019 12.4386 2.04181 12.6368 1.417C12.1217 1.72412 11.5529 1.94106 10.9468 2.06213C10.4577 1.54131 9.76056 1.21875 9.00006 1.21875C7.52456 1.21875 6.33669 2.41638 6.33669 3.88456C6.33669 4.09581 6.35456 4.29894 6.39844 4.49231C4.18275 4.38425 2.22219 3.32231 0.905125 1.70463C0.675187 2.10356 0.540313 2.56019 0.540313 3.05175C0.540313 3.97475 1.01562 4.79294 1.72413 5.26662C1.29594 5.2585 0.875875 5.13419 0.52 4.93837C0.52 4.9465 0.52 4.95706 0.52 4.96762C0.52 6.26275 1.44381 7.3385 2.65525 7.58631C2.43831 7.64563 2.20187 7.67406 1.9565 7.67406C1.78587 7.67406 1.61362 7.66431 1.45194 7.62856C1.79725 8.684 2.77713 9.45994 3.94225 9.48512C3.0355 10.1944 1.88419 10.6218 0.637812 10.6218C0.41925 10.6218 0.209625 10.6121 0 10.5852C1.18056 11.3466 2.57969 11.7812 4.0885 11.7812C8.99275 11.7812 11.674 7.71875 11.674 4.19737C11.674 4.07956 11.6699 3.96581 11.6643 3.85287C12.1932 3.4775 12.6376 3.00869 13 2.46919Z"
//           fill="#101237"
//         />
//       </g>
//       <defs>
//         <clipPath id="clip0_1_155">
//           <rect width="13" height="13" fill="white" />
//         </clipPath>
//       </defs>
//     </svg>
//   ),
// };

// export const linkedin = {
//   icon: (
//     <svg
//       width="11"
//       height="11"
//       viewBox="0 0 11 11"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <g clip-path="url(#clip0_1_162)">
//         <path
//           d="M10.9973 11V10.9996H11V6.96531C11 4.99173 10.5751 3.47144 8.26789 3.47144C7.15873 3.47144 6.41439 4.0801 6.11052 4.65714H6.07843V3.65569H3.89081V10.9996H6.16873V7.36314C6.16873 6.40569 6.35023 5.47985 7.53593 5.47985C8.70423 5.47985 8.72164 6.57252 8.72164 7.42456V11H10.9973Z"
//           fill="#101237"
//         />
//         <path
//           d="M0.181519 3.65601H2.46219V10.9999H0.181519V3.65601Z"
//           fill="#101237"
//         />
//         <path
//           d="M1.32092 0C0.591708 0 0 0.591708 0 1.32092C0 2.05013 0.591708 2.65421 1.32092 2.65421C2.05013 2.65421 2.64183 2.05013 2.64183 1.32092C2.64138 0.591708 2.04967 0 1.32092 0V0Z"
//           fill="#101237"
//         />
//       </g>
//       <defs>
//         <clipPath id="clip0_1_162">
//           <rect width="11" height="11" fill="white" />
//         </clipPath>
//       </defs>
//     </svg>
//   ),
// };
